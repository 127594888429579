import React, { FC } from 'react';
import { Row, Column, Image, Text, Link } from '../..';
import { ImageType } from '../../atoms/image/types';
import styles from './blog-author-bio.module.css';

interface BlogAutorBioProps {
  name: string;
  image: {
    src: string;
    alt: string;
  };
  bio: string;
}

export const BlogAuthorBio: FC<BlogAutorBioProps> = ({ name, image, bio }) => {
  return (
    <div className={styles.container}>
      <Row
        cols={24}
        borders={['bottom top', 'bottom top']}
        className={styles.bioInfo}
        gridLength={24}
      >
        <Column cols={[22, 5]} offset={1} borders={['left right', 'right']}>
          <div className={`flex-center ${styles.bioLeft}`}>
            <Image
              className={styles.bioImage}
              blob
              type={ImageType.ROUND}
              {...image}
            />
          </div>
        </Column>
        <Column cols={[22, 17]} offset={[1, 0]} borders={['left right', '']}>
          <div className={`flex-col ${styles.bioRight}`}>
            <Text className="mb-xxs" variant="u2" color="dark-green">
              {name}
            </Text>
            <Text variant="p3a" color="dark-green">
              {bio}
            </Text>
            <Link
              color="sage"
              className="mb-sm"
              variant="u1bLink"
              destination="/"
            >
              Read Full Bio
            </Link>
          </div>
        </Column>
      </Row>
    </div>
  );
};
