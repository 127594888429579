import React from 'react';
import { BlogAuthorBio } from './blog-author-bio';
import JSONData from '../../content/blog/author-bio.json';
import styles from './blog-author-bio.module.css';

export const BlogAuthorBioDemo = () => {
  return (
    <div className={styles.demoWrap}>
      <BlogAuthorBio {...JSONData} />
    </div>
  );
};
