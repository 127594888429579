import React from 'react';
import { Layout, Module } from '..';
import { BlogAuthorBioDemo } from '../organisms/blog-author-bio/blog-author-bio-demo';

export default function MentalHealth() {
  return (
    <Layout>
      <Module>
        <BlogAuthorBioDemo />
      </Module>
    </Layout>
  );
}
